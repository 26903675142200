import React from "react";
import SearchResultPage from "../../../templates/search-results-map-template";

const SearchResultResiSalesMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/properties-map/for-sale/"
                pcategorytype="residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="For Sale"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    );
};

export default SearchResultResiSalesMap;